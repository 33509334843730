import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Deadlifts 5×2\\@85% 1RM`}</p>
    <p>{`then,`}</p>
    <p>{`15:00 amrap of:`}</p>
    <p>{`20-Calorie Row`}</p>
    <p>{`15-Pullups`}</p>
    <p>{`10-Deadlifts (225/155)`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`This Saturday is our free partner Halloween Workout so bring a
friend and wear a costume! Best costume wins a prize. Classes are at
9:00 & 10:15am.`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      